/* NAVBAR CODE  */
nav{
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center; 
    align-items: center;
    height: 80px;
    width: 100vw;
}
nav ul{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0;
    width: 100%;
    gap: 30px;
    padding-right: 30px;
}
nav ul li{
    display: inline-block;
    line-height: 50px;
}

.checkbtn{
    font-size: 30px;
    color: white;
    float: right;
    line-height: 50px;
    margin-right: 20px;
    cursor: pointer;
    display: none;
}
#check{
    display: none;
}

.nav-link {
    font-size: 13px;
    color: white;
    text-decoration: none;
}

.nav-link:hover {
    color: #82adff;
}

.nav-link.active {
    color: grey;
}


@media (max-width: 858px) {
    nav{
        justify-content: flex-start;
    }
    .Burger{
        margin-right: 20px;
    }
    .checkbtn {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100vw;
        height: 50px;
        margin: 0;
        padding: 0;
        position: absolute;
        z-index: 1;
    }
    .nav-link {
        font-size: 20px;
        color: white; 
    }
    nav ul {
        position: fixed;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        margin: 0;
        padding: 20px;
        width:50vw;
        height: 100vh;
        background-color: #29024a;
        top: 0px; 
        right: -100%;
        text-align: center;
        transition: all 0.5s;
        flex-direction: column;
        z-index: 200;
        gap: 0px;
        background-image: url('../../1_MediaAssets/BrandAssets/Texture.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    nav ul li {
        display: block;
        margin: 10px;
        line-height: 30px;
        color: white;
    }

    #check:checked ~ ul {
        right: 0;
    }
    .nav-link{
        font-size: 17px;
    }
}
