/* CursorTracker.css */
.cursor-container {
    position: fixed;
    top: 0;
    left: 0;
    pointer-events: none; /* Allow cursor to pass through the circles */
    z-index: 1000; /* Ensure the circles are above other content */
}

.large-circle {
    position: absolute;
    width: 30px;
    height: 30px;
    border: 2px solid #7c02e3;
    border-radius: 50%;
}

.small-circle {
    position: absolute;
    width: 15px;
    height: 15px;
    background-color: #7c02e3;
    border-radius: 50%;
}


@media screen and (max-width: 1000px) { 
    .cursor-container {
        display: none;
    }
}