.productcard {
  max-width: 400px;
  margin: 20px;
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  flex-direction: column;
  background-color: #232024;
  padding: 20px 10px;
  border-radius: 10px;
  transition: transform 0.5s ease;
}

.productcard:hover {
  transform: translateY(-5px);
}

.productcard-img-top {
  width: 95%;
  border-radius: 10px;
}

.productcard-avail {
  font-size: 12px;
}

.productcard-img-top:hover {
  transform: translateY(-15%);
  box-shadow: rgba(147, 63, 226, 0.25) 0px 13px 47px -5px,
    rgba(147, 71, 180, 0.3) 0px 8px 16px -8px;
}

.productcard-title {
  color: #9f5dd4;
  font-size: 18px;
  margin: 0;
  padding: 0;
}

.productcard-buttons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.productcard-button {
  background-color: #9f5dd4;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.productcard-button:hover {
  background-color: #7b45a4;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: #232024;
  padding: 20px;
  cursor: pointer;
  border-radius: 10px;
  width: 80%;
  max-width: 700px;
  max-height: 80vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  position: relative;
}

.modal-content {
  display: flex;
  background-color: #232024;
  flex-direction: column;
  gap: 10px;
}

.modal-content h1 {
  color: #9f5dd4;
  font-size: 30px;
  margin: 20px;
  padding: 0;
}

.modal-content p {
  margin-bottom: 10px;
  font-size: 12px;
  color: #999999;
  font-weight: 900;
  margin-left: 20px;
}

.modal-main-image {
  text-align: center;
}

.modal-main-image img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  width: 95%;
}

.modal-thumbnails {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.modal-thumbnails img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 5px;
  cursor: pointer;
}

.modal-thumbnails img:hover {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}
.modal-desc h3 {
  color: #9f5dd4;
  font-size: 16px;
  margin: 20px;
  padding: 0;
}

.modal-desc p {
  margin-bottom: 10px;
  font-size: 14px;
  color: #999999;
  font-weight: 900;
  margin-left: 20px;
}
