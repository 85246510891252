.tc-content-container {
  width: 100%;
  height: 100%;
  background-color: black;
  background-image: url("../../1_MediaAssets/BrandAssets/Backgrounds/PurpleBanner.jpg");
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tc-hero {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
  width: 100vw;
  text-align: center;
  position: relative;
}

.tc-hero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.81); 
  z-index: 1;
}

.tc-hero h1 {
  position: relative;
  z-index: 2;
  width: 97vw;
  max-width: 1200px;
  color: white;
  font-size: 2.5rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  text-align: center;
}

.tc-desc-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100vw;
  max-width: 1200px;
  gap: 10px;
  padding: 20px;
}

.tc-desc-section h3 {
  color: white;
  font-size: 1.7rem;
  text-align: left;
  width: 100%;
  padding: 5px;
}

.tc-desc-section p, .tc-desc-section ul {
  padding: 10px;
  font-size: 14px;
  text-align: left;
}

.tc-desc-section p {
  line-height: 1.6;
  margin: 10px 0; 
}

.tc-desc-section ul {
  line-height: 1.6;
}