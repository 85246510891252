.intro-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 20px;
  text-align: left;
}

.intro-text {
  flex: 2;
}

.intro-text h1 {
  font-size: 2rem;
  padding: 20px;
  margin-top: 40px;
  animation: float-in 1s ease-out;
  color: #c384f5;
}

.intro-text p {
  font-size: 14px;
  line-height: 1.6;
  margin-bottom: 50px;
  padding: 20px;
}
.team-section {
  text-align: center;
  padding: 50px 20px;
}

.team-section h1 {
  font-size: 2.5rem;
  margin-bottom: 40px;
  animation: float-in 1s ease-out;
  color: #c384f5;
}

.team-members {
  display: flex;
  justify-content: center;
  gap: 150px;
  flex-wrap: wrap;
}

.team-member {
  text-align: center;
  width: 200px;
}

.team-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 20px;
}

.team-member h3 {
  font-size: 16px;
  margin-bottom: 10px;
}

.team-member p {
  font-size: 14px;
  color: #666;
  margin-bottom: 40px;
}

.leadership-section {
  text-align: center;
  padding: 50px 20px;
}

.leadership-section h1 {
  font-size: 2.5rem;
  margin-bottom: 40px;
  margin-top: 40px;
  animation: float-in 1s ease-out;
  color: #c384f5;
}

.leadership-members {
  justify-content: center;
  gap: 20px;
}

.leadership-member {
  display: flex;
  width: 99%;
  max-width: 1350px;
  background-color: #2f0c4b;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 20px;
}

.leadership-member img {
  object-fit: cover;
  width: 25%;
  margin-bottom: 20px;
}

.leadership-content {
  padding: 40px;
  color: white;
  text-align: left;
  animation: float-in 1s ease-out;
}

.leadership-content h3 {
  font-size: 1.5rem;
  color: #c384f5;
}

.leadership-content h4 {
  font-size: 1rem;
  margin-bottom: 10px;
  color: #ccc;
}

.leadership-content p {
  font-size: 14px;
  color: #eee;
}

.about-section {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 50px 20px;
}

.about-content {
  display: flex;
  width: 100%;
  max-width: 1200px;
  align-items: center;
  gap: 20px;
}

.about-text {
  flex: 2;
}

.about-text h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-align: left;
  color: #c384f5;
}

.about-text p {
  font-size: 14px;
  margin-bottom: 20px;
  text-align: left;
}
