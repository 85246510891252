html, body{
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  background-color: #29024A;
  color: white;
}

button{
  background: white;
  width: 170px;
  height: 50px;
  border:0;
}

p, h1{
  margin: 0;
  padding: 0;
}

.bookacall{
  background:transparent;
  color:white;
  border: 1px solid white;
  transition: 0.4s;
}

.bookacall:hover{
  background-color: white;
  color:#29024A;
}


.para{
  color:white;
  font-size:14px;
  width: 100%;
}

.parahead{
  color:white;
}

.mainAbout{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
}

.videoAboutDiv{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  padding: 30px;
}

.paraAboutDiv{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 50%;
  padding: 30px;
}

.navtext{
  font-size: 16px;
  margin-left: 15px;
  margin-top: 10px;
  color: white
}



/* CONTACT US PAGE  */
.contactdiv{
  border: 0.5px solid #29024A;
  gap: 5px;
  padding: 20px;
  width: 500px;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}


/* HOME PAGE  */
/* WHERE INNOVATION MEETS IMPACT TEXT  */
.every{
  font-size: 40px;
  color: white;
  letter-spacing: 3px;
  font-weight: 300;
  text-align: center;
  padding: 20px;
  margin-bottom: -15px;
}


/* FOOTER  */
.footer{
  margin-top: 50px;
  background: black;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.foottext{
  color: white;
  padding: 0;
  margin: 0;
  margin-right: 20px;
}

.foottext2{
  text-decoration: underline;
  color: white;
  padding: 0;
  margin: 0;
  margin-right: 20px;
  font-size: 12px;
}

.facebook:hover {
  color: #3b5998;
}
.facebook:hover span { 
  background: #3b5998;
}
.whatsapp:hover {
  color: #25D366;
}
.whatsapp:hover span { 
  background: #25D366;
}
.instagram:hover {
  color: #c32aa3;
}
.instagram:hover span { 
  background: #c32aa3;
}

.linkedin, .facebook, .instagram, .whatsapp{
  color: white;
  font-size: 22px;
}

.linkedin:hover {
  color: #0072b1;
}
.linkedin:hover span { 
  background: #0072b1;
} 

.socialbutton{
  background-color: black;
  width: 50px;
  height: 50px;
  margin: -2px;
}


/* SCROLLBAR  */
/* SCROLL BAR  */
::-webkit-scrollbar {
  display: none;
}


/* TEXT HIGHLIGHT  */
::selection {
  background: white;
  color: #29024A;
}

/* FONT  */ 
@font-face{
  font-family: myFont;
  src:url('./Fonts/GothamOffice-Regular.otf');
}
*{
  font-family: myFont;
}

@font-face{
  font-family: myFontBold;
  src:url('./Fonts/GothamOffice-Bold.otf');
}
h1, .fontSpecial{
  font-family: myFontBold;
}


/* SERVICES PAGE LAYOUT  */

.servicemain{
  width: 100vw;
  display:flex;
  justify-content:center;
  align-items:center;
  flex-direction:column;
}

.serviceitem{
  display:flex;
  width: 100vw;
  height: 400px;
  display:flex;
  justify-content:center;
  align-items:center;
  border-top: 2px solid #29024A;
}

.serviceimg{
  width: 400px;
  height: 400px; 
  background-repeat:no-repeat;
  background-position: center center;
  background-size:cover;
}

.servicetextR{
  width: 80%; 
  height: 400px; 
  display: flex; 
  justify-content: center;
  background: #29024A; 
  flex-direction: column; 
  color: #29024A; 
  padding: 80px;
}

.servicetextL{
  width: 80%;
  height: 400px;
  display:flex;
  justify-content: center;
  align-items: left;
  background: white;
  flex-direction:column;
  color: #29024A;
  padding: 80px;
}

.servicetextheaderR{
  font-weight: 900;
  font-size: 35px;
  margin-bottom: 10px;
}

.servicetextheaderL{
  font-weight: 900;
  font-size: 35px;
  margin-bottom: 10px;
  text-align: right;
}

.serviceCoverVid{
  background-size:cover;
  height:100vh;
  width:100vw;
  overflow:hidden;
  position:relative;
}
.VideoVideo{
  width: 100%;
}

/* CLIENTS CAROUSEL  */
.clientsMobile{
  display: none;
}



/* NAVBAR CODE  */
.navvy {
  margin: 0;
  padding: 0;
  z-index: 10000000000000;
  /* background-color: #ff0000; */
}

.conntainer {
  width: 100vw;
  margin: 0;
  display: flex;
  /* background: red; */
  justify-content: center;
  align-items: center;
  height: 100%;
  z-index: 10000000000000;
}

.navbar {
  height: 60px;
  /* background-color: red; */
  position: relative;
}


.menu-icon {
  display: none;
}


.nav-elements ul {
  display: flex;
  justify-content: center;
  list-style-type: none;
}

.nav-elements ul li:not(:last-child) {
  margin-right: 60px;
}

.nav-elements ul a {
  font-size: 16px;
  font-weight: 400;
  color: #2f234f;
  text-decoration: none;
}

.nav-elements ul a.active {
  color: #cccccc;
  font-weight: 500;
  position: relative;
}

.nav-elements ul a.active::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #cccccc;
}
 
@media (max-width: 768px) {
  .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
  }
}

@media (max-width: 600px) {
  .menu-icon {
    display: block;
    cursor: pointer;
  }

  .nav-elements {
    position: absolute;
    right: 0;
    top: 40px;
    width: 0px;
    height: calc(100vh - 60px); 
    transition: all 0.3s ease-in;
    overflow: hidden;
    background-color: black;
  }

  .nav-elements.active {
    width: 270px;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
  }

  .nav-elements ul li {
    margin-right: unset;
    margin-top: 22px;
  }
}




/* MAPS CODE  */
.maps{
  background-image: url('../BrandAssets/Maps.png');
  height: 200px;
  width: 300px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.maps:hover{
  opacity: 0.5;
  transition: 0.5s;
}


/* HERO PROJECTS CAROUSEL */

#slideHero{
  width: 1000vw;
  height: 100vh;
  }
  .item{
  width:200px;
  height:300px;
  background-position: 50% 50%;
  display: inline-block;
  transition: 0.5s;
  background-size: cover;
  position: absolute;
  z-index: 1;
  top:50%;
  transform: translate(0,-50%);
  border-radius: 20px;
  box-shadow:  30px 30px 50px #505050;
  }
  .item:nth-child(1),
  .item:nth-child(2){
  left:0;
  top:0;
  transform: translate(0,0);
  border-radius: 0;
  width:100%;
  height:100vh;
  box-shadow: none;
  }
  .item:nth-child(3){
  left:50%;
  }
  .item:nth-child(4){
  left:calc(50% + 220px);
  }
  .item:nth-child(5){
  left:calc(50% + 440px);
  }
  .item:nth-child(n+6){
  left:calc(50% + 660px);
  opacity: 0;
  }
  
  .item .content{
  position: absolute;
  top:50%;
  left:100px;
  width:450px;
  text-align: left;
  padding:0;
  color:#eee;
  transform: translate(0,-50%);
  display: none;
  }
  
  .item .name{
  font-size: 40px;
  font-weight: bold;
  opacity: 0;
  animation:showcontent 1s ease-in-out 1 forwards;
  color: white;
  }
  
  @media screen and (max-width: 750px) {
  .item .content{
    left: 20px;
    top: 35%;
    width: 350px;
  }
  .item{
    width:120px;
    height:200px;
    top: 68%;
  }
  .item:nth-child(4){
    left:calc(50% + 130px);
    z-index: 1;
  }
  .item .name{
    font-size: 35px;
  }
  }
  
  .item:nth-child(2) .content{
  display: block;
  }
  
  
  
  .item:nth-child(1)::after {
  content: "";
  background: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  }
  
  .item:nth-child(2)::after {
  content: "";
  background: rgba(0, 0, 0, 0.7); /* Adjust the opacity as needed */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  }
  
  
  
  .item .des{
  margin:20px 0;
  opacity: 0;
  animation:showcontent 1s ease-in-out 0.3s 1 forwards;
  color: white
  }
  .item button{
  padding:10px 20px;
  border:none;
  opacity: 0;
  animation:showcontent 1s ease-in-out 0.6s 1 forwards
  }
  @keyframes showcontent{
  from{
      opacity: 0;
      transform: translate(0,100px);
      filter:blur(33px);
  }to{
      opacity: 1;
      transform: translate(0,0);
      filter:blur(0);
  }
  }

  .buttons{
    position: absolute;
    bottom:80px;
    z-index: 222222;
    text-align: center;
    width:100%;
    }
    .buttons button{
    width:65px;
    height:40px;
    border:1px solid white;
    transition: 0.5s;
    background-color: transparent;
    }.buttons button:hover{
    border:1px solid #29024A;
    background-color: white;
    color: black;
    }
    



/** Icon Hover Effect 4 **/
.hover-effect4 {
  min-height: 40px;
  /* background: #009688; */
  text-align: right;
  }
  
  .hover-effect4 ul {
  padding-top: 10px;
  padding-bottom: 0;
  }
  
  .hover-effect4 ul li {
  display: inline-block;
  }
  
  .hover-effect4 ul li a {
  font-size: 16px;
  color: #29024A;
  margin: 0px 5px 0px 0px;
  line-height: 100px;
  display: inline-block;
  border-radius: 8px;
  width: 30px;
  height: 30px;
  background: white;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  }
  
  .hover-effect4 ul li:hover a {
  background: white;
  border: 1px solid #29024A;
  
  }
  
  .hover-effect4 ul li a i{
  color: #29024A;
  }
  
  .hover-effect4 ul li:hover a i{
  color: #29024A;
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  }


  #prev, #next{
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
  }

  #prev:hover{
    color: #29024A;
  }

  #next:hover{
    color: #29024A;
  }

  .con section {
    scroll-snap-align: start;
    width: 100%;
    height: 100vh;

}



.con section:nth-of-type(even) {

    color: white;
    height: 100vh;
}


@media only screen and (max-width: 710px) {
  #serdiv{
    flex-direction: column;
  }
}

.con {
  width: 100vw;
  height: 100vh;
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  overflow-x: hidden;
}


/* // GALLERY MAIN CODE  */


/* Body & Image List Styles
-------------------------------------------------------------- */
.gallerymain {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: red;
  width: 100vw; 
  background: black;
  padding: 2.5%;
  }
  .img-list {
  list-style: none;
  padding: 0;
  margin: 0;
  }
  .img-list li {
  border: 5px solid transparent;
  box-sizing: border-box;
  width: 300px;
  float: left;
  position: relative;
  cursor: pointer;
  }
  .img-list img {
  max-width: 100%;
  vertical-align: middle;
  }
  
  /* Image Placeholder Styles
  -------------------------------------------------------------- */
  .img-placeholder:before,
  .img-placeholder:after {
  padding-top: 33.33%;
  content: "";
  display: block;
  }  
  .img-placeholder {
  background: #222;
  box-shadow: 0 0 0 8px #222 inset, 0 0 0 9px #333 inset;
  color: #444;
  line-height: 0;
  text-align: center;
  display: block;
  }
  
  /* The hover effect
  -------------------------------------------------------------- */
  .img-list li:before {
  transition: all .5s ease;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #333;
  transform: scale(0);
  }
  .img-list li:hover:before {
  opacity: .5;
  transform: scale(1);
  }
  .img-list li:after {
  transition: all .6s ease .2s;
  content: "";
  position: absolute;
  top: 8px;
  left: 8px;
  right: 8px;
  bottom: 8px;
  border: 1px solid #aaa;
  background: #000;
  opacity: 0;
  transform: scale(0);
  }
  .img-list li:hover:after {
  opacity: .35;
  transform: scale(1);
  }

.image-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 18px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.img-list li:hover .image-text {
  opacity: 1;
}


/* SERVICES CSS STARTS HERE  */
.hero-section {
  position: relative;
  width: 100%;
  height: 60vh;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
}
.hero-title {
  font-size: 48px;
  margin-bottom: 20px;
  animation: float-in 1s ease-out;
}
.hero-subtitle {
  font-size: 24px;
}
@keyframes float-in {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
.card {
  position: relative;
  width: 300px;
  height: 300px;
  perspective: 1000px;
  margin: 20px;
  overflow: hidden;
  cursor: pointer;
}
.card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 1s;
  transform-style: preserve-3d;
}
.card-front, .card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: transform 1s ease-in-out;
}
.card-front {
  background: white;
  z-index: 2;
}
.card-back {
  background: linear-gradient(135deg, #000000, #29024A); 
  transform: translateY(100%);
  z-index: 1;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 8px;
}
.card:hover .card-back {
  transform: translateY(0);
  z-index: 2;
}
.card:hover .card-front {
  transform: translateY(-100%);
  z-index: 1;
}
.card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.card-title {
  font-size: 24px;
  margin-bottom: 10px;
  font-weight: bold;
  color: white;
  
}
.card-description {
  font-size: 14px;
  padding: 10px;
  color: aliceblue;
}
.services-page {
  background-size: cover;
  background-attachment: fixed;
  padding: 0px 20px 50px 20px;
}
.services-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  perspective: 900px;
}

.outlined-text {
  color: transparent; 
  -webkit-text-stroke: 1px #8c4ac1; 
  text-transform: uppercase; 
  text-align: center;
  font-weight: bold;
}


/* RESPONSIVENESS  */
@media (max-width: 1000px) {
  .card {
    width: 300px;
    height: 300px;
  }
  .card-title {
    font-size: 18px;
  }
  .card-description {
    font-size: 14px;
    padding: 8px;
  }
  .outlined-text {
    font-size: 48px;
  }
}

/* SERVICES CSS ENDS HERE  */

.mainDivContact {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 0;
  background: #29024a;
  margin: 20px 5px;
}

.ContactForm {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 1);
  flex-direction: column;
  padding: 40px 20px;
  width: 95vw;
  height: 100%;
  max-width: 500px;
  gap: 10px;
  border: 3px solid #29024a;
}

.imagediv{
  width: 60px;
  display: flex;
  align-items: flex-start;
} 

.cta {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  background-color: #29024a; 
  color: #fff;
  transition: width 0.7s;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  position: relative;
}

.inputContact {
  width: 100%;
  background: transparent;
  border: 1px solid #8c4ac1;
  font-size: 13px;
  padding: 12px;
  color: white;
}


.cta:hover {
  width: 170px;
  background-color: rgb(255, 255, 255);
  color: black;
}

.cta .iii {
  opacity: 1;
  transition: opacity 0.5s;
}

.cta:hover .iii {
  opacity: 0;
}

.cta .button-text {
  opacity: 0;
  transition: opacity 0.5s;
  width: 100%;
  text-align: center;
  position: absolute; /* Position absolute to overlap with the icon */
  color: black;
}

.cta:hover .button-text {
  opacity: 1;
}


.introduction {
  position: relative;
  background-size: cover;
  background-position: bottom;
  padding: 100px 20px;
  text-align: center;
  color: #29024A;
}

.introduction::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7); 
  z-index: 1;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 2;
  max-width: 800px;
  margin: 0 auto;
}

.welcome-tag {
  color: white; 
  font-size: 14px;
  letter-spacing: 2px;
  margin-bottom: 10px;
}

/* MEDIA TAG  */
@media only screen and (max-width: 1000px) {
  .content {
    margin-bottom: 200px;
  }
  .mainAbout{
    flex-direction: column;
    padding: 10px;
  }
  .videoAboutDiv{
    width: 100%;
    padding: 0;
    background-color: green;
  }
  .paraAboutDiv{
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 20px 0px 0px 0px;
  }

  .paraAboutDiv h1{
    font-size: 30px;
  }

  .VideoVideo{
    width: 95vw;
  }

  .foottext{
    font-size: 11px;
    text-align: right;
  } 

  .footer{
    margin-top: 250px;
  }

  .tree{
    display: none;
  }        
  .para{
    color:white;
    font-size:14px;
    text-align: center;
  }
  .parahead{
    text-align: center;
  }
  .videoAboutDiv{
    width: 80%; 
    justify-content: center;
    align-items: center;
  }
  .every{
    font-size: 30px;
    margin-bottom: 2px;
  }
  .navtext{
    font-size: 12px;
  }
  .servicemain{
    width: 100vw;
  }
  .serviceitem{
    width: 100vw;
    height: 170px;
    border-top: 1px solid #29024A;
  }
  .serviceimg{
    width: 100%;
    height: 170px; 
  }
  .servicetextR{
    background: white;
    color: #29024A;
    border-left: 1px dotted #29024A;
    width: 500px;
    height: 170px; 
    padding: 20px;
  }
  .servicetextL{
    background: white;
    color: #29024A;
    border-right: 1px dotted #29024A;
    width: 500px;
    height: 170px; 
    padding: 20px;
  }
  .servicetextheaderR{
    font-size: 12px;
  }
  .servicetextheaderL{
    font-size: 12px;
  }
  .servicetextPara{
    font-size: 9px;
  }
  .hideinphone{
    display: none;
  }
  .clientsDesktop{
    display: none;
  }
  .clientsMobile{
    display:flex;
    width: 290px;
  }
  .YoutubePhone{
    margin-top: 40px;
    display: flex; 
  }
  .YoutubeDesktop{
    display: none;
  }
  .img-list li {
    width: 100%;
    clear: both; /* Ensure each image takes up a new row */
  }
  
  .imagediv{
    display: none;
  }
}

/* PRIVACY POLICY PAGE  */
.priv-content-container {
  width: 100%;
  height: 100%;
  background-color: black;
  background-image: url('../../1_MediaAssets/BrandAssets/Backgrounds/PurpleBanner.jpg');
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  }

.priv-hero {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
  width: 100vw;
  text-align: center;
  position: relative;
}
  
.priv-hero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.81); 
  z-index: 1; 
}

.priv-hero h1 {
  position: relative; 
  z-index: 2;
  width: 97vw;
  max-width: 1200px;
  color: white;
  font-size: 2.5rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  text-align: center;
}

.priv-desc-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    max-width: 1200px;
    text-align: center;
    gap: 20px;
    padding: 10px;
}

.priv-desc-section p {
  line-height: 1.6;
  margin: 30px;
  flex: 1;
  text-align: left;
  font-size: 14px;
  padding: 20px;
}

/* END OF PRIVACY POLICY STYLING  */

/* BLOGS SECTION */
.blogcard {
  max-width: 400px;
  margin: 20px;
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  flex-direction: column;
  background-color: #232024;
  padding: 20px 10px;
  border-radius: 10px;
  transition: transform 0.5s ease; /* Smooth transition for transform */
}

.blogcard:hover {
  transform: translateY(-5px); /* Lift the card upwards on hover */
}

.blogcard-img-top {
  width: 95%;
  border-radius: 10px;
}

.blogcard-title{
  color: #9f5dd4;
  font-size: 16px;
  margin: 0;
  padding: 0;
}

.blogcard-date{
  margin-bottom: 10px;
  font-size: 12px;
  color: #999999;
  font-weight: 900;
}

.blogcard-by{
  font-size: 10px;
  color: #999999;
  font-weight: 900;
  margin-bottom: 10px;
}


.blog-details-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
}

.hero-sectionw {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
  width: 100vw;
  text-align: center;
  position: relative;
}

.hero-sectionw::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(19, 0, 36, 0.9); /* Adjust opacity and color here */
  z-index: 1; /* Ensure the overlay is above the background image */
}

.hero-titlew {
  position: relative; /* Ensure title is on top of overlay */
  z-index: 2; /* Ensure the title is above the overlay */
  width: 97vw;
  max-width: 1200px;
  color: white;
  font-size: 2.5rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  max-width: 1200px;
  text-align: center;
  gap: 20px;
}

.content-section {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #232024;
  padding: 20px;
  /* border-radius: 10px; */
}

.left .section-image {
  order: 1;
  margin-right: 20px;
}

.right .section-image {
  order: 2;
  margin-left: 20px;
}

.full-width .text-container {
  width: 100%;
  text-align: left;
}

.text-left {
  text-align: left;
  order: 2;
}

.text-right {
  text-align: right;
  order: 1;
}

.text-full-width {
  text-align: left;
}

.text-container {
  width: 100%;
}

.section-image {
  width: 40%;
  height: auto;
}

.section-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #c384f5;
}

.section-paragraph {
  font-size: 14px;
}

@media (max-width: 1000px) {
  .content-section {
    flex-direction: column;
    width: 90%;
    gap: 0px;
    margin:0px;
  }

  .section-image {
    width: 100%;
    margin-bottom: 0px; 
  }

  .text-container {
    width: 100%;
    text-align: left;
    margin-bottom: 0px;
  }

  .left .section-image {
    margin-right: 0px;
    margin-bottom: 20px;
  }
  
  .right .section-image {
    margin-left: 0px;
    margin-top: 20px;
  }

  .hero-titlew {
    font-size: 23px;
  }

  .hero-sectionw {
    height: 350px;
  }

  .section-paragraph{
    font-size: 14px;
  }
}