.slider {
    height: 186px;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 100%;
  }
  
  .slide-track {
    display: flex;
    animation: scroll 100s linear infinite;
  }
  
  .slide {
    height: 186px;
    width: 336px;
  }
  
  .img {
    height: 186px;
    width: 336px;
    object-fit: contain;
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
  }
  
  .img:hover {
    transform: scale(1.1);
  }
  
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-336px * 19));
    }
  }
  